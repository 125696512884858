/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Arial", sans-serif;
  background-color: #f4f4f4;
  line-height: 1.6;
  color: #333;
}

/* Header Styling */
header {
  background-color: #333;
  color: #fff;
  padding: 1rem 0;
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Navigation Styling */
nav {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  background-color: #444;
  padding: 0.5rem 0;
}

nav a {
  color: #fff;
  padding: 0.5rem 1rem;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;
}

nav a:hover {
  background-color: #575757;
  border-radius: 5px;
  color: #ffcc00;
}

/* Main Container */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

/* Heading Styles */
h1,
h2 {
  color: #333;
  margin-bottom: 1rem;
  font-weight: 600;
}

/* Form Styling */
form {
  display: flex;
  flex-direction: column;
}

form input,
form textarea,
form select {
  margin-bottom: 1rem;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

form input:focus,
form textarea:focus,
form select:focus {
  border-color: #333;
  outline: none;
}

form button {
  padding: 0.75rem;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

form button:hover {
  background-color: #575757;
}

/* Quote Table Styling */
.quote-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  font-size: 0.9rem;
}

.quote-table th,
.quote-table td {
  padding: 0.75rem;
  border: 1px solid #ddd;
  text-align: left;
}

.quote-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .container {
    padding: 1rem;
  }

  form input,
  form textarea,
  form select,
  form button {
    font-size: 0.9rem;
    padding: 0.5rem;
  }

  .quote-table th,
  .quote-table td {
    padding: 0.5rem;
  }
}

@media (max-width: 480px) {
  nav {
    flex-direction: column;
  }

  nav a {
    margin-bottom: 0.5rem;
  }

  header,
  nav,
  .container {
    padding: 0.5rem;
  }

  .quote-table th,
  .quote-table td {
    font-size: 0.85rem;
  }
}
